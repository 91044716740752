import img1 from "../../assets/Deals/Artboard 1.png"
import img2 from "../../assets/Deals/Artboard 2.png"
import img3 from "../../assets/Deals/Artboard 3.png"
import img4 from "../../assets/Deals/Artboard 4.png"
import img5 from "../../assets/Deals/Artboard 5.png"
import img6 from "../../assets/Deals/Artboard 6.png"



export const dealsdata = [
    {
      
      image: `${img1}`,
      // text:`MONSTER:` ,
      //   texttwo:`2 for $5 (16 oz)` ,
      //   textthree:`` ,
    },
    {
     
      image: `${img2}`,
      // text: "CORE WATER ",
      // texttwo:`C4: 2 for $5` ,
      // textthree:`CELCIUS: 2 for $5` ,
    },
    {
     
      image: `${img3}`,
      // text:'Free Ice Bag with the ' ,
      // texttwo:`purchase of` ,
      // textthree:`Two Cases of Beer!` ,
    },
    {
        image: `${img4}`,
      //   text: " PEPSI: ",
      //   texttwo:`2 for $4 on all Pepsi flavors,` ,
      // textthree:`Mountain Dew & Starry (20 oz)` ,
      },
      {
        image: `${img5}`,
      //   text: " PEPSI: ",
      //   texttwo:`2 for $4 on all Pepsi flavors,` ,
      // textthree:`Mountain Dew & Starry (20 oz)` ,
      },
      {
        image: `${img6}`,
      //   text: " PEPSI: ",
      //   texttwo:`2 for $4 on all Pepsi flavors,` ,
      // textthree:`Mountain Dew & Starry (20 oz)` ,
      },
  ];