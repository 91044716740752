import { useState } from "react";
import personLogo from "../..//assets/Careers/person-logo.png";
import mailLogo from "../../assets/Careers/mail-logo.png";
import phoneLogo from "../../assets/Careers/phone-logo.png";
import attachmentLogo from "../../assets/Careers/attachment-logo.png";
import messageLogo from "../../assets/Careers/message-logo.png";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./index.css";
import axios from "axios";
import { error } from "jquery";

export default function Form() {
  const [formData, setFormData] = useState({
    fullName: "",
    email: "",
    phone: "",
    resume: null,
    comments: "",
  });

  const [errors, setErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    // Trimming the value
    let limitedValue = value.trimStart();

    // Apply character limits
    if (name === "fullName" && limitedValue.length > 100) {
      limitedValue = limitedValue.slice(0, 100); // Limit fullName to 100 characters
    } else if (name === "email" && limitedValue.length > 100) {
      limitedValue = limitedValue.slice(0, 100); // Limit email to 100 characters
    } else if (name === "comments" && limitedValue.length > 250) {
      limitedValue = limitedValue.slice(0, 300); // Limit comments to 300 characters
    }

    // Clear errors if the input is not empty and errors exist
    if (limitedValue.length > 0 && Object.keys(errors).length !== 0) {
      console.log("working");
      setErrors({});
    }

    // Update form data with the limited value
    setFormData((prevData) => ({
      ...prevData,
      [name]: limitedValue,
    }));
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    const allowedFiles = [
      "application/pdf",
      "application/msword",
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    ];

    // check for empty file
    if (!file) {
      return;
    }

    const isAllowed = allowedFiles.some((fileType) => {
      return fileType === file.type;
    });

    // check for pdf or word file
    if (!isAllowed) {
      toast.error("File should be pdf or word.", {
        position: "top-center",
      });
      return;
    }

    // check for file size (2mb allowed)
    if (file.size > 2 * 1024 * 1024) {
      toast.error(
        "File size exceeds the 2 MB limit. Please select a smaller file.",
        {
          position: "top-center",
        }
      );
      return;
    }

    setFormData((prevData) => ({
      ...prevData,
      resume: file,
    }));
  };

  const validateForm = (trimmedFormData) => {
    const newErrors = {};

    // Regular expressions
    const nameRegex = /^[A-Za-z\s]+$/;
    const phoneRegex = /^\+1-\d{3}-\d{3}-\d{4}$/;
    const emailRegex = /^[\w.%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/;

    if (!trimmedFormData.fullName) {
      newErrors.fullName = "Full name is required.";
    } else if (!nameRegex.test(trimmedFormData.fullName)) {
      newErrors.fullName = "Please enter a valid name (alphabets only).";
    }

    if (!trimmedFormData.email) {
      newErrors.email = "Email is required.";
    } else if (!emailRegex.test(trimmedFormData.email)) {
      newErrors.email = "Please enter a valid email address.";
    }

    if (!trimmedFormData.phone) {
      newErrors.phone = "Phone number is required.";
    } else if (!phoneRegex.test(trimmedFormData.phone)) {
      newErrors.phone = "Please enter a valid number (+1-234-567-8910).";
    }

    if (!trimmedFormData.comments) {
      newErrors.comments = "Comments are required.";
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    setIsSubmitting(true);

    // Trim all fields before validation
    const trimmedFormData = {
      ...formData,
      fullName: formData.fullName.trim(),
      email: formData.email.trim(),
      phone: formData.phone.trim(),
      comments: formData.comments.trim(),
    };

    const isValid = validateForm(trimmedFormData);

    if (isValid) {
      let data = new FormData();
      data.append("email", trimmedFormData.email);
      data.append("fullName", trimmedFormData.fullName);
      data.append("phone", trimmedFormData.phone);
      data.append("comments", trimmedFormData.comments);
      data.append("resume", formData.resume);

      let config = {
        method: "post",
        maxBodyLength: Infinity, // Set the max size for the request body
        url: "https://oms.smartkidstrainer.com/shipEmail/sendEmailContactNW.php",
        data: data, // Attach the FormData object
      };

      try {
        const res = await axios.request(config);

        if (res.data.Status !== 200) {
          throw new Error(res.data.Msg || "An error occurred");
        }

        setFormData({
          fullName: "",
          email: "",
          phone: "",
          resume: null,
          comments: "",
        });

        toast.success("Form Submitted.", {
          position: "top-center",
        });
      } catch (err) {
        toast.error(err.message, {
          position: "top-center",
        });
      } finally {
        setIsSubmitting(false);
      }
    } else {
      setIsSubmitting(false);
    }
  };

  return (
    <form id="form" onSubmit={handleSubmit} method="Post">
      <div className="form-head">Apply Now</div>

      <div className="input-cont">
        <label htmlFor="full-name-field">
          <img src={personLogo} alt="person" />
        </label>
        <input
          type="text"
          placeholder="Full Name"
          name="fullName"
          value={formData.fullName}
          onChange={handleInputChange}
          id="full-name-field"
        />
      </div>
      {errors.fullName && <div className="error-text">{errors.fullName}</div>}

      <div className="input-cont">
        <label htmlFor="email-field">
          <img src={mailLogo} alt="email" />
        </label>
        <input
          type="text"
          placeholder="Email Address"
          name="email"
          value={formData.email}
          onChange={handleInputChange}
          id="email-field"
        />
      </div>
      {errors.email && <div className="error-text">{errors.email}</div>}

      <div className="input-cont">
        <label htmlFor="phone-field">
          <img src={phoneLogo} alt="phone" />
        </label>
        <input
          type="text"
          placeholder="Phone Number"
          name="phone"
          value={formData.phone}
          onChange={handleInputChange}
          id="phone-field"
        />
      </div>
      {errors.phone && <div className="error-text">{errors.phone}</div>}

      <div className="input-file-cont">
        <label htmlFor="file-field">
          <div>
            <img src={attachmentLogo} alt="attachment" />
            <span>
              {formData.resume
                ? formData.resume.name
                : "Attach a Resume (Optional)"}
            </span>
            <input
              id="file-field"
              type="file"
              hidden
              onChange={handleFileChange}
            />
          </div>
        </label>
      </div>

      <div className="input-comments-cont">
        <label htmlFor="comments-field">
          <img src={messageLogo} alt="message" />
        </label>
        <textarea
          placeholder="Describe all past relevant experiences"
          rows={10}
          name="comments"
          value={formData.comments}
          onChange={handleInputChange}
          id="comments-field"
        ></textarea>
      </div>
      {errors.comments && <div className="error-text">{errors.comments}</div>}

      <div className="btn-cont">
        <button type="submit" disabled={isSubmitting}>
          {isSubmitting ? "Submitting..." : "Submit"}
        </button>
      </div>
      <ToastContainer autoClose={2000} />
    </form>
  );
}
