import joblink from "../../assets/Careers/joblink.svg"
export const new_jobs = [
    {
        src: `${joblink}`,
        job_title: "Stocker,",
        job_description: " Houston TX.",
        url: "stocker"
    },
    {
        src: `${joblink}`,
        job_title: "Cashier,",
        job_description: " Houston TX.",
        url: "cashier"
    },
    {
        src: `${joblink}`,
        job_title: "Store manager,",
        job_description: " Houston TX.",
        url: "store-manager"
    },
];