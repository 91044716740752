import React, { useEffect } from "react";
import { useParams } from "react-router-dom"; // Import useParams to access dynamic route params
import "./index.css";
import tickLogo from "../../../assets/Careers/tick.png";
import Form from "../../Form";
import Layout from "../../layout/layout";
import { data } from "../../data/JobsData.js";
import Section01 from "../Careers/Section01/Section01.jsx";

export default function Job() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  
  const { jobId } = useParams();

  const job = data.find((job) => job.url === jobId);

  if (!job) {
    return (
      <Layout title="Job">
        <Section01 />
        <div className="job-not-found-wrapper">
          <p>Job not found!</p>
        </div>
      </Layout>
    );
  }

  return (
    <Layout title="Job">
      <Section01 />
      <div className="job-section" id="job-sect">
        <h1>{job.heading}</h1>
        <div className="section02-container">
          <div className="text-wrapp">
            {job.sections.map((section, index) => (
              <div className="text-con" key={section.title + index}>
                <h4>{section.title}</h4>
                {section.text ? (
                  <p>{section.text}</p>
                ) : (
                  <div className="list-wrapper">
                    {section.list.map((listItem, idx) => (
                      <div className="list-item" key={listItem.boldText + idx}>
                        <img src={tickLogo} alt="tick" />
                        <div>
                          <span>{listItem.boldText}</span> {listItem.text}
                        </div>
                      </div>
                    ))}
                  </div>
                )}
              </div>
            ))}
          </div>
          <div className="form-wrapper">
            <Form />
          </div>
        </div>
      </div>
    </Layout>
  );
}
