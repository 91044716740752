import React from "react";
import "./Section03.css";
import servicesCar from "../../../../../assets/Services/sevicesCar.png";
import spotFreeRinse from "../../../../../assets/Services/spot free rinse.png";
import ceramicPunch from "../../../../../assets/Services/ceramic punch.png";
import wheelAndRockerBlaster from "../../../../../assets/Services/wheel & rocker blaster.png";
import tripleFoam from "../../../../../assets/Services/triple foam.png";
import underBodyWash from "../../../../../assets/Services/under body wash.png";
import softGlassWash from "../../../../../assets/Services/soft gloss wash.png";
import superDry from "../../../../../assets/Services/super dry.png";
import foamBath from "../../../../../assets/Services/foam bath.png";
import rasberryClearCoat from "../../../../../assets/Services/rasberry clear coat.png";
import wavePremiumWax from "../../../../../assets/Services/wave premium wax.png";
import wheelClean from "../../../../../assets/Services/wheel clean.png";
import sliderfirst from "../../../../../assets/Services/slider-first.png";
import secondslide from "../../../../../assets/Services/secondslide.png";
import thirdslide from "../../../../../assets/Services/thirdslide.png";
import Slider from "react-slick";

const Section03 = () => {
  var settings = {
    dots: false,
    infinite: true,
    speed: 500,
    arrows: false,
    autoplay: true,
    autoplaySpeed: 2000, 
    slidesToShow: 6,
    slidesToScroll: 6,
    responsive: [
      {
        breakpoint: 1261,
        settings: {
          slidesToShow: 5,
          slidesToScroll: 5,
        }
      },

      {
        breakpoint: 901,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 4,
        }
      },

      {
        breakpoint: 701,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3
        }
      },

      {
        breakpoint: 571,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2
        }
      },

      {
        breakpoint: 470,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        }
      },
    ]
  };
  return (
    <>
      <div className="container-fluid text-white service01-Sec03">
        <div className="mx-auto position-relative">
          <div className="d-flex flex-column align-items-center servicesCarInfo">
            <img
              width={480}
              className="servicesCar img-fluid"
              src={servicesCar}
              alt=""
              draggable="false"
            />
            {/* <h2>Expert Car Wash Services:
                            Unleash Your Vehicle's Shine!</h2>
                        <p>Experience the Ultimate Pampering for Your Vehicle: Our Professional Car Wash Services Guarantee a Gleaming Finish and Immaculate Cleanliness. Treat Your Ride to the Care It Deserves, and Let Its True Shine Shine Through! Trust Our Expert Team to Revitalize Every Inch, Leaving Your Car Looking and Feeling Fresh and Renewed.</p> */}
          </div>
          <div className="py-4 car-wash-type-sect">
            {/* <img className='img-fluid servicesMainImage' src={servicesMainImage} alt="" draggable='false' /> */}
            <div className="car-wash-type">
              <h2>bulls eyes</h2>
              <div className="car-wash-type-cont">
                <div className="car-slider">
                  <Slider {...settings}>
                    <div className="img-cont">
                      <img src={spotFreeRinse} width={80} height={80}></img>
                    </div>
                    <div className="img-cont">
                      <img src={ceramicPunch} width={80} height={80}></img>
                    </div>
                    <div className="img-cont">
                      <img
                        src={wheelAndRockerBlaster}
                        width={80}
                        height={80}
                      ></img>
                    </div>
                    <div className="img-cont">
                      <img src={tripleFoam} width={80} height={80}></img>
                    </div>
                    <div className="img-cont">
                      <img src={underBodyWash} width={80} height={80}></img>
                    </div>
                    <div className="img-cont">
                      <img src={softGlassWash} width={80} height={80}></img>
                    </div>
                    <div className="img-cont">
                      <img src={superDry} width={80} height={80}></img>
                    </div>
                    <div className="img-cont">
                      <img src={foamBath} width={80} height={80}></img>
                    </div>
                    <div className="img-cont">
                      <img src={rasberryClearCoat} width={80} height={80}></img>
                    </div>
                    <div className="img-cont">
                      <img src={wavePremiumWax} width={80} height={80}></img>
                    </div>
                    <div className="img-cont">
                      <img src={wheelClean} width={80} height={80}></img>
                    </div>
                  </Slider>
                </div>
                <div className="car-text">
                  <img src={sliderfirst} alt="" />
                  {/* best value
                  <br></br>
                  $14 */}
                </div>
              </div>
            </div>

            <div className="car-wash-type2">
              <h2>basic bull</h2>
              <div className="car-wash-type-cont2">
                <div className="car-text">
                  <img src={secondslide} alt="" />
                  {/* best value
                  <br></br>
                  $12 */}
                </div>
                <div className="car-slider2">
                  <Slider {...settings}>
                    <div className="img-cont">
                      <img src={underBodyWash} width={80} height={80}></img>
                    </div>
                    <div className="img-cont">
                      <img src={tripleFoam} width={80} height={80}></img>
                    </div>
                    <div className="img-cont">
                      <img
                        src={wheelAndRockerBlaster}
                        width={80}
                        height={80}
                      ></img>
                    </div>
                    <div className="img-cont">
                      <img src={rasberryClearCoat} width={80} height={80}></img>
                    </div>
                    <div className="img-cont">
                      <img src={softGlassWash} width={80} height={80}></img>
                    </div>
                    <div className="img-cont">
                      <img src={superDry} width={80} height={80}></img>
                    </div>
                    <div className="img-cont">
                      <img src={foamBath} width={80} height={80}></img>
                    </div>
                    <div className="img-cont">
                      <img src={spotFreeRinse} width={80} height={80}></img>
                    </div>
                    <div className="img-cont">
                      <img src={wheelClean} width={80} height={80}></img>
                    </div>
                  </Slider>
                </div>
              </div>
            </div>

            <div className="car-wash-type">
              <h2>bulls wash</h2>
              <div className="car-wash-type-cont">
                <div className="car-slider">
                  <Slider {...settings}>
                    <div className="img-cont">
                      <img src={rasberryClearCoat} width={80} height={80}></img>
                    </div>
                    <div className="img-cont">
                      <img src={softGlassWash} width={80} height={80}></img>
                    </div>
                    <div className="img-cont">
                      <img src={underBodyWash} width={80} height={80}></img>
                    </div>
                    <div className="img-cont">
                      <img src={tripleFoam} width={80} height={80}></img>
                    </div>
                    <div className="img-cont">
                      <img src={superDry} width={80} height={80}></img>
                    </div>
                    <div className="img-cont">
                      <img src={foamBath} width={80} height={80}></img>
                    </div>
                    <div className="img-cont">
                      <img src={spotFreeRinse} width={80} height={80}></img>
                    </div>
                  </Slider>
                </div>
                <div className="car-text">
                  <img src={thirdslide} alt="" />
                  {/* best value
                  <br></br>
                  $10 */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Section03;
