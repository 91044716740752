export const data = [
  {
    heading: "stocker",
    url: "stocker",
    sections: [
      {
        title: "Position Overview:",
        text: "We are seeking a dedicated and detail-oriented Stocker to join our team. The ideal candidate will be responsible for maintaining well-stocked and organized shelves, ensuring a clean and efficient store environment, and providing exceptional customer service.",
      },
      {
        title: "Requirements:",
        list: [
          {
            boldText: "Physical Stamina:",
            text: "Capable of lifting heavy items and standing for long periods.",
          },
          {
            boldText: "Attention to Detail:",
            text: "Ability to organize products following company guidelines.",
          },
          {
            boldText: "Time Management:",
            text: "Effectively prioritize tasks to ensure shelves are stocked promptly.",
          },
          {
            boldText: "Basic Math Skills:",
            text: "Competency in performing simple calculations for inventory tracking.",
          },
          {
            boldText: "Communication Skills:",
            text: "Ability to collaborate with team members and supervisors.",
          },
          {
            boldText: "Customer Service Skills:",
            text: " Friendly and approachable demeanor when assisting customers.",
          },
          {
            boldText: "Experience:",
            text: "At least 6 months of experience in retail, stocking, or a related field preferred.",
          },
        ],
      },
      {
        title: "Key Responsibilities:",
        list: [
          {
            boldText: "Restock Shelves:",
            text: "Ensure shelves are consistently filled, products are correctly placed, and labels are visible.",
          },
          {
            boldText: "Inventory Monitoring:",
            text: "Track stock levels and report low inventory to management.",
          },
          {
            boldText: "Store Maintenance:",
            text: "Keep storage and sales areas clean, tidy, and organized.",
          },
          {
            boldText: "Product Placement:",
            text: "Follow planograms and company guidelines for arranging merchandise.",
          },
          {
            boldText: "Expiration Checks:",
            text: "Regularly inspect products for expiration dates and remove outdated items.",
          },
          {
            boldText: "Customer Assistance:",
            text: "Help customers locate products and answer stock-related inquiries.",
          },
          {
            boldText: "Equipment Care:",
            text: "Maintain stockroom tools and equipment in good working condition.",
          },
        ],
      },
      {
        title: "Why Join Us?",
        text: "We offer a supportive and fast-paced work environment where your skills and dedication are recognized. This role provides an excellent opportunity for growth and development in the retail industry.",
      },
      {
        title: "Apply Today!",
        text: "Become a vital part of our team and help us create an exceptional shopping experience for our customers.",
      },
    ],
  },
  {
    heading: "cashier",
    url: "cashier",
    sections: [
      {
        title: "Position Overview:",
        text: "We are looking for an enthusiastic and reliable Cashier to join our team. The ideal candidate will provide exceptional customer service while efficiently handling transactions and maintaining an organized checkout area. This role is critical in ensuring a positive shopping experience for our customers.",
      },
      {
        title: "Requirements:",
        list: [
          {
            boldText: "Education:",
            text: "High school diploma or equivalent preferred.",
          },
          {
            boldText: "Experience:",
            text: "At least 6 months of experience in retail or customer service preferred.",
          },
          {
            boldText: "Mathematical Skills:",
            text: "Basic math skills for accurate handling of transactions.",
          },
          {
            boldText: "Technical Skills:",
            text: "Familiarity with cash registers, payment systems, and basic computer usage.",
          },
          {
            boldText: "Communication Skills:",
            text: "Strong verbal skills for effective interaction with customers and team members.",
          },
          {
            boldText: "Attention to Detail:",
            text: "Accuracy in processing transactions and managing cash.",
          },
          {
            boldText: "Physical Stamina:",
            text: "Ability to stand for extended periods and lift light to moderate weights.",
          },
        ],
      },
      {
        title: "Key Responsibilities:",
        list: [
          {
            boldText: "Provide Excellent Customer Service:",
            text: "Greet customers, answer questions, and assist with inquiries in a friendly and professional manner.",
          },
          {
            boldText: "Process Transactions:",
            text: "Accurately handle cash, credit, and debit card payments, ensuring correct tendering and change.",
          },
          {
            boldText: "Manage Cash Drawer:",
            text: "Maintain sufficient cash levels in the drawer and balance it at the end of the shift.",
          },
          {
            boldText: "Promote Sales:",
            text: "Inform customers about store promotions, discounts, and new products.",
          },
          {
            boldText: "Assist with Inventory:",
            text: "Occasionally support inventory replenishment or checks as needed.",
          },
          {
            boldText: "Maintain Workspace Cleanliness:",
            text: "Keep the checkout area clean, organized, and stocked with supplies.",
          },
          {
            boldText: "Handle Returns and Exchanges:",
            text: "Process returns and exchanges in line with store policies.",
          },
          {
            boldText: "Record Keeping:",
            text: "Update daily financial logs or records as required.",
          },
        ],
      },
      {
        title: "Why Join Us?",
        text: "We offer a welcoming and fast-paced work environment, providing opportunities for growth within the retail industry. Your skills and commitment will play a vital role in ensuring a seamless shopping experience for our customers.",
      },
      {
        title: "Apply Today!",
        text: "Take the next step in your career by joining our dedicated team of professionals.",
      },
    ],
  },
  {
    heading: "store manager",
    url: "store-manager",
    sections: [
      {
        title: "Position Overview:",
        text: "We are seeking a dedicated and experienced Store Manager to lead our team and oversee daily operations. The ideal candidate will combine strong leadership skills with a passion for customer service, ensuring the store operates efficiently while achieving sales targets and fostering a positive work environment.",
      },
      {
        title: "Requirements:",
        list: [
          {
            boldText: "Education:",
            text: "A high school diploma is required; prior experience is required.",
          },
          {
            boldText: "Experience:",
            text: "Minimum of 2 years in retail management or a supervisory role.",
          },
          {
            boldText: "Leadership Skills:",
            text: "Proven ability to motivate and manage a team effectively.",
          },
          {
            boldText: "Communication Skills:",
            text: "Strong verbal and written communication for interacting with staff, customers, and stakeholders.",
          },
          {
            boldText: "Customer Service Orientation:",
            text: "Commitment to delivering exceptional customer service and resolving issues efficiently.",
          },
          {
            boldText: "Analytical Skills:",
            text: "Proficient in interpreting sales reports and customer feedback to drive strategic decisions.",
          },
          {
            boldText: "Time Management:",
            text: "Skilled in prioritizing tasks and managing time in a fast-paced environment.",
          },
          {
            boldText: "Adaptability:",
            text: "This job requires learning new skills quickly and handling different parts of our extensive POS system.",
          },
        ],
      },
      {
        title: "Key Responsibilities:",
        list: [
          {
            boldText: "Team Leadership:",
            text: "Recruit, train, and supervise staff, creating schedules and assigning tasks to optimize workflow.",
          },
          {
            boldText: "Drive Sales Performance:",
            text: "Monitor sales metrics and develop strategies to meet and exceed targets.",
          },
          {
            boldText: "Inventory Oversight:",
            text: "Manage stock levels, conduct regular audits, and coordinate with suppliers to ensure availability of products.",
          },
          {
            boldText: "Customer Engagement:",
            text: "Guarantee excellent customer service, addressing complaints or issues promptly and effectively.",
          },
          {
            boldText: "Operational Management:",
            text: "Oversee daily store functions, including cash handling, merchandising, and adherence to company policies.",
          },
          {
            boldText: "Promotional Campaigns:",
            text: "Plan and execute marketing initiatives to boost in-store traffic and sales.",
          },
          {
            boldText: "Reporting and Analysis:",
            text: "Compile and present sales, inventory, and budget reports to upper management.",
          },
          {
            boldText: "Compliance and Safety:",
            text: "Ensure the store complies with health, safety, and retail regulations, maintaining a secure and welcoming environment for staff and customers.",
          },
        ],
      },
      {
        title: "Why Join Us?",
        text: "We offer a dynamic and rewarding work environment where your leadership will make a tangible impact on the store's success. This role provides opportunities for professional growth and development in a fast-paced retail setting.",
      },
      {
        title: "Apply Today!",
        text: "If you’re a results-driven leader with a passion for customer satisfaction and operational excellence, we’d love to hear from you!",
      },
    ],
  },
];
