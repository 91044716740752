import "./Section01.css";
import careersbanner from "../../../../assets/Careers/career-ban-new-img.png";

const Section01 = () => {
  return (
    <div className="careers-section01" id="car-sect1">
      <div className="section01-container">
        <div className="text-cont">
          <h1>apply <span>now!</span></h1>
          <p>Join the Now and Forever Family!.</p>
        </div>
        <div><img src={careersbanner} className="career-banner-img"></img></div>
      </div>
    </div>
  );
};

export default Section01;
