import React from "react";
import "./Section02.css"
import Card from "../../dealscard/dealscard"
import { dealsdata } from "../../../data/dealsdata"


const Section02 = () => {
    const data = dealsdata
    console.log(data);

    return (<>
       <div className="card-containerArticle11 deals-article">
      {data.map((card, index) => (
        <Card
          key={index}
          image={card.image}
          // text={
          //   <span dangerouslySetInnerHTML={{ __html: `${card.text}<br />${card.texttwo}<br />${card.textthree}` }} />
          // }
        />
      ))}
    </div>
    </>)
}
export default Section02;