import sliderimage1 from "../../assets/Home/home-page-banner-1.c5a50263693ef5e3346c.webp"
import sliderimage2 from "../../assets/Home/banner2updated.png";
import sliderimage3 from "../../assets/Home/banner3-upd.png";
import sliderimage4 from "../../assets/Home/home-page-banner-4.png"
import sliderimage5 from "../../assets/Home/deskbanner4.png"

// import sliderimage4 from "../../assets/Home/";
// import sliderimage5 from "../../assets/Home/home-page-banner-5.jpg";





export const Slider = [
    {
        id:"1", 
        src: `${sliderimage1}`,
      
    },
    {
        src: `${sliderimage5}`,
        id:"2",
        btn_text : 'Learn More',
        loc : 'https://www.nowandforeverrewards.com/Home.asp'
    },
    {   
        id:"3",
        src: `${sliderimage2}`,
     
    },
    {   
        id:"4",
        src: `${sliderimage3}`,
     
    }, 
    {
        id:"5",
        src: `${sliderimage4}`,
     
    },
];
